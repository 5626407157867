module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-amplitude-analytics/gatsby-browser.js'),
      options: {"plugins":[],"apiKey":"b628b01d51444f2c9ccce8078a8a6bd6","head":false,"respectDNT":true,"amplitudeConfig":{"saveEvents":true,"includeUtm":true,"includeReferrer":true},"environments":["development","production"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-rollbar/gatsby-browser.js'),
      options: {"plugins":[],"accessToken":"1456ae3ff40c401cb9b66424cc2b41b2","captureUncaught":true,"captureUnhandledRejections":true,"payload":{"environment":"production"}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
